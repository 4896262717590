<template>
    <div>
        <transition name="fade">
            <section v-if="items && items.length > 0" class="timeline">
                <div class="container">
                    <div v-for="(appointment,index) in items" class="timeline-item">
                        <div class="timeline-img"></div>
                        <div :class="config.etablissement.waiting_list && appointment.pending ? 'opacity' : ''" class="timeline-content">
                            <div :class="index%2 === 0 ? 'text-right' : 'text-left'">{{appointment.date_debut_readable}}
                                <i v-if="config.etablissement.waiting_list && appointment.pending">(en liste d'attente)</i>
                            </div>
                            <div class="content mt-3 mb-3" :class="index%2 !== 0 ? 'margin-content' : ''">
                                <div v-if="appointment.doctor">
                                    {{ $t('mes-rdv.docteur') }} : {{appointment.doctor.name + ' ' + appointment.doctor.firstname}}
                                </div>
                                <div v-if="appointment.office">
                                    {{ $t('mes-rdv.lieu') }} : {{appointment.office.name+', '+ appointment.office.address}}
                                </div>
                                <div v-else>
                                    {{ $t('mes-rdv.lieu') }} : pas de lieu défini
                                </div>
                                <div v-if="appointment.reason">
                                    {{ $t('mes-rdv.motif') }} : {{appointment.reason.label}}
                                </div>
                                <div v-else>
                                    {{ $t('mes-rdv.motif') }} : pas de motif défini
                                </div>
                                <div>
                                    {{ $t('mes-rdv.debut') }} : {{moment(appointment.date_debut).format('DD-MM-YYYY [à] HH[h]mm')}}
                                </div>
                                <div>
                                    {{ $t('mes-rdv.fin') }} : {{moment(appointment.date_fin).format('DD-MM-YYYY [à] HH[h]mm')}}
                                </div>
                                <div v-if="appointment.comment">
                                    {{ $t('global.commentaire') }} : {{appointment.comment}}
                                </div>
                            </div>
                            <div>
                                <button v-if="deleteAllowed(appointment) && displayDeleteButton" @click="onDelete(appointment)" class="btn btn-danger mr-3 btn-sm">
                                    <span><i v-if="printing !== appointment.id" class="bi bi-trash clickable mr-3" ></i></span>
                                    <span v-if="printing && printing === appointment.id" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                                    {{ $t('global.supprimer') }}
                                </button>
                                <button v-if="(config.etablissement.display_print_button) && !(config.etablissement.waiting_list && appointment.pending)" @click="onPrint(appointment)"
                                    :class="index%2 !== 0 ? 'margin-content' : ''" class="btn btn-primary btn-sm">
                                    <span><i v-if="printing !== appointment.id" class="bi bi-printer-fill clickable mr-3" ></i></span>
                                    <span v-if="printing && printing === appointment.id" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    {{ $t('global.imprimer') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="row" v-else>
                <div class="col-md-12">
                    <div class="alert alert-info">
                        {{ $t('mes-rdv.aucun-rdv') }}
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    /*
        Exemple pris sur https://codepen.io/savalazic/pen/QKwERN/
     */
    import axios from 'axios'
    import {mapGetters} from "vuex";
    import {namespaces} from "@/store/namespaces";
    import moment from 'moment'
    import {EventBus} from "@/event-bus";

    export default {
        name: "Timeline",
        props: {
            items: {
                required: true,
                type: Array
            },
            displayDeleteButton: {
                required: false,
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                config: Vue.$config,
                printing: false,
                moment: moment,
            }
        },
        computed: {
            ...mapGetters({
                getUser: namespaces.auth + 'getUser',
                getApiToken: namespaces.auth + 'getApiToken',
                getOffices: namespaces.calendar+'getOffices',
                getDoctors: namespaces.calendar+'getDoctors',
                getReasons: namespaces.calendar+'getReasons',
            }),
        },
        methods: {
            async onPrint(appointment) {
                this.printing = appointment.id
                try {
                    let res = await axios({
                        url: '/appointment/print/'+appointment.id,
                        method: 'GET',
                        responseType: 'blob',
                        headers: {
                            "content-type": "application/json",
                            "Accept": "application/json, text/plain",
                            "Authorization": "Bearer " + this.getApiToken
                        }
                    })
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'rendez-vous-'+moment(appointment.date_debut).format('DD-MM-YY_HH-mm')+'-'+appointment.doctor.name+'.pdf');
                    document.body.appendChild(link);
                    link.click();
                }catch (e) {
                    this.$toast.open({
                        message: this.$t('global.erreur'),
                        type: 'error'
                    })
                    console.log(e)
                }
                this.printing = false
            },
            async onDelete(appointment) {
                EventBus.$emit('open-generic-modal-confirmation',{
                    title: this.$t('global.confirmation-suppression-titre'),
                    content: this.$t('global.confirmation-suppression-contenu'),
                    object: appointment
                })
            },
            handleConfirmDelete(data) {
                if(data.result) {
                    this.$emit('delete-appointment', data.object)
                }
            },
            deleteAllowed(appointment) {
                let hoursLimit = this.config.etablissement.allow_delete_appointment
                if(hoursLimit) {
                    let now = moment()
                    if(moment(appointment.date_debut).subtract(hoursLimit, 'hours').isAfter(now)) {
                        return true
                    }
                }
                return false
            }
        },
        mounted() {
            EventBus.$on('result-generic-modal-confirmation', (data)=> this.handleConfirmDelete(data))
        },
        beforeDestroy() {
            EventBus.$off('result-generic-modal-confirmation')
        }
    }
</script>

<style scoped lang="scss">
    $primary: #3F51B5;
    $dark-primary: #303F9F;
    $light-primary: #C5CAE9;
    $text: #FFFFFF;
    $primary-text: #212121;
    $secondary-text: #757575;
    $accent: #FF4081;

    html, body {
        overflow-x: hidden;
    }

    .text-right {
        @media (max-width: 768px) {
            text-align: left!important;
        }
    }

    .margin-content {
        @media (min-width: 768px) {
            //margin-left: 75px;
        }
    }

    .timeline {
        position: relative;

        &::before {
            content: '';
            background: $light-primary;
            width: 5px;
            height: 95%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .timeline-item {
        width: 100%;
        margin-bottom: 70px;

        &:nth-child(even) {

            .timeline-content {
                float: right;
                //padding: 40px 30px 10px 30px;

                .date {
                    right: auto;
                    left: 0;
                }

                &::after {
                    content: '';
                    position: absolute;
                    border-style: solid;
                    width: 0;
                    height: 0;
                    top: 30px;
                    left: -15px;
                    border-width: 10px 15px 10px 0;
                    border-color: transparent #f5f5f5 transparent transparent;
                }
            }
        }

        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }

    .timeline-content {
        position: relative;
        width: 45%;
        padding: 10px 30px;
        border-radius: 4px;
        background: #f5f5f5;
        box-shadow: 0 20px 25px -15px rgba(0, 0, 0, .3);

        &::after {
            content: '';
            position: absolute;
            border-style: solid;
            width: 0;
            height: 0;
            top: 30px;
            right: -15px;
            border-width: 10px 0 10px 15px;
            border-color: transparent transparent transparent #f5f5f5;
        }
    }

    .opacity {
        opacity: 0.5;
    }

    .timeline-img {
        width: 30px;
        height: 30px;
        background: $primary;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        margin-top: 25px;
        margin-left: -15px;
    }

    a {
        background: $primary;
        color: $text;
        padding: 8px 20px;
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 20px;
        margin-top: 10px;
        display: inline-block;
        border-radius: 2px;
        box-shadow: 0 1px 3px -1px rgba(0, 0, 0, .6);

        &:hover, &:active, &:focus {
            background: darken($primary, 10%);
            color: $text;
            text-decoration: none;
        }

    }

    .timeline-card {
        padding: 0!important;

        p {
            padding: 0 20px;
        }

        a {
            margin-left: 20px;
        }
    }

    blockquote {
        margin-top: 30px;
        color: $secondary-text;
        border-left-color: $primary;
        padding: 0 20px;
    }

    .date {
        background: $accent;
        display: inline-block;
        color: $text;
        padding: 10px;
        position: absolute;
        top: 0;
        right: 0;
    }

    @media screen and (max-width: 768px) {

        .timeline {

            &::before {
                left: 50px;
            }

            .timeline-img {
                left: 50px;
            }

            .timeline-content {
                max-width: 100%;
                width: auto;
                margin-left: 70px;
            }

            .timeline-item {

                &:nth-child(even) {

                    .timeline-content {
                        float: none;

                    }
                }

                &:nth-child(odd) {

                    .timeline-content {

                        &::after {
                            content: '';
                            position: absolute;
                            border-style: solid;
                            width: 0;
                            height: 0;
                            top: 30px;
                            left: -15px;
                            border-width: 10px 15px 10px 0;
                            border-color: transparent #f5f5f5 transparent transparent;
                        }
                    }

                }
            }
        }

    }
</style>
