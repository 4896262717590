<template>
    <div id="my-appointments" class="mb-5">
        <div class="main-title">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h3><i class="bi bi-list-ul mr-3"></i>{{$t('mes-rdv.titre')}}</h3>
                        <div class="underline"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row" >
                <div class="col-md-12">
                    <transition name="fade" mode="out-in">
                        <timeline v-if="!loading" :items="appointments" @delete-appointment="onDeleteAppointment"></timeline>
                        <timeline-skeleton v-else></timeline-skeleton>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import calendarApi from '@/api/calendar'
    import {mapGetters} from "vuex";
    import {namespaces} from "@/store/namespaces";
    import moment from 'moment'
    import Timeline from "@/components/front/my-appointments/Timeline";
    import TimelineSkeleton from "@/components/front/my-appointments/TimelineSkeleton.vue";
    export default {
        name: "MyAppointments",
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Mes rendez-vous',
        },
        components: {
            TimelineSkeleton,
            Timeline
        },
        data() {
            return {
                appointments: null,
                moment: moment,
                config: Vue.$config,
                printing: false,
                loading: true
            }
        },
        computed: {
            ...mapGetters({
                getUser: namespaces.auth + 'getUser',
                getApiToken: namespaces.auth + 'getApiToken',
                getOffices: namespaces.calendar+'getOffices',
                getDoctors: namespaces.calendar+'getDoctors',
                getReasons: namespaces.calendar+'getReasons',
            }),

        },
        methods: {
            async getAppointments() {
                let res = await calendarApi.getUserAppointments({
                    user_id: this.getUser.id,
                    app_id: this.config.app_id
                })
                return res.data.appointments
            },
            async onDeleteAppointment(appointment) {
                this.loading = true
                try {
                    let res = await calendarApi.delete(appointment.id)
                    this.appointments = await this.getAppointments()
                }catch (e) {
                    Vue.$toast.open({
                        message: 'Une erreur s\'est produite',
                        type: 'error',
                    });
                    console.log(e)
                }
                Vue.$toast.open({
                    message: 'Rendez-vous supprimé avec succès!.',
                    type: 'success',
                });
                this.loading = false
            }
        },
        async mounted() {
            this.loading = true
            this.appointments = await this.getAppointments()
            this.loading = false
        }

    }
</script>

<style scoped>
    #spinner {
        position: fixed;
        top:60px;
        right: 5px;
        height: 4rem;
        width: 4rem;
        z-index: 10;
    }
    @media(max-width: 992px) {
        #spinner {
            height: 2rem;
            width: 2rem;
        }
    }
</style>
