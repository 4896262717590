<template>
    <div>
        <div class="container">
            <div class="timeline">
                <div v-for="(index) in 25" class="timeline-item">
                    <div class="timeline-img"></div>
                    <div class="timeline-content">
                        <div :class="(index+1)%2 === 0 ? 'text-right' : 'text-left'">
                            <PuSkeleton height="15px"  width="150px"></PuSkeleton>
                        </div>
                        <div class="content mt-3 mb-3" :class="index%2 !== 0 ? 'margin-content' : ''">
                            <div>
                                <PuSkeleton height="15px" class="mr-2" :width="Math.random() * (175 - 30) + 30+'px'"></PuSkeleton>
                                <PuSkeleton height="15px" class="mr-2" :width="Math.random() * (175 - 30) + 30+'px'"></PuSkeleton>
                            </div>
                            <div>
                                <PuSkeleton height="15px" class="mr-2" :width="Math.random() * (175 - 30) + 30+'px'"></PuSkeleton>
                                <PuSkeleton height="15px" class="mr-2" :width="Math.random() * (175 - 30) + 30+'px'"></PuSkeleton>
                            </div>
                            <div>
                                <PuSkeleton height="15px" class="mr-2" :width="Math.random() * (175 - 30) + 30+'px'"></PuSkeleton>
                                <PuSkeleton height="15px" class="mr-2" :width="Math.random() * (175 - 30) + 30+'px'"></PuSkeleton>
                            </div>
                            <div>
                                <PuSkeleton height="15px" class="mr-2" :width="Math.random() * (175 - 30) + 30+'px'"></PuSkeleton>
                                <PuSkeleton height="15px" class="mr-2" :width="Math.random() * (175 - 30) + 30+'px'"></PuSkeleton>
                            </div>
                            <div>
                                <PuSkeleton height="15px" class="mr-2" :width="Math.random() * (175 - 30) + 30+'px'"></PuSkeleton>
                                <PuSkeleton height="15px" class="mr-2" :width="Math.random() * (175 - 30) + 30+'px'"></PuSkeleton>
                            </div>
                        </div>
                        <div>
                            <PuSkeleton height="30px"  width="120px"></PuSkeleton>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "TimelineSkeleton",
        props: {

        },
        data() {
            return {

            }
        },
        computed: {

        },
        methods: {
        },
        mounted() {

        },
        beforeDestroy() {

        }
    }
</script>

<style scoped lang="scss">
$primary: #3F51B5;
$dark-primary: #303F9F;
$light-primary: #C5CAE9;
$text: #FFFFFF;
$primary-text: #212121;
$secondary-text: #757575;
$accent: #FF4081;

html, body {
    overflow-x: hidden;
}

.text-right {
    @media (max-width: 768px) {
        text-align: left!important;
    }
}

.margin-content {
    @media (min-width: 768px) {
        //margin-left: 75px;
    }
}

.timeline {
    position: relative;

    &::before {
        content: '';
        background: $light-primary;
        width: 5px;
        height: 95%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

.timeline-item {
    width: 100%;
    margin-bottom: 70px;

    &:nth-child(even) {

        .timeline-content {
            float: right;
            //padding: 40px 30px 10px 30px;

            .date {
                right: auto;
                left: 0;
            }

            &::after {
                content: '';
                position: absolute;
                border-style: solid;
                width: 0;
                height: 0;
                top: 30px;
                left: -15px;
                border-width: 10px 15px 10px 0;
                border-color: transparent #f5f5f5 transparent transparent;
            }
        }
    }

    &::after {
        content: '';
        display: block;
        clear: both;
    }
}

.timeline-content {
    position: relative;
    width: 45%;
    padding: 10px 30px;
    border-radius: 4px;
    background: #f5f5f5;
    box-shadow: 0 20px 25px -15px rgba(0, 0, 0, .3);

    &::after {
        content: '';
        position: absolute;
        border-style: solid;
        width: 0;
        height: 0;
        top: 30px;
        right: -15px;
        border-width: 10px 0 10px 15px;
        border-color: transparent transparent transparent #f5f5f5;
    }
}

.opacity {
    opacity: 0.5;
}

.timeline-img {
    width: 30px;
    height: 30px;
    background: $primary;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-top: 25px;
    margin-left: -15px;
}

a {
    background: $primary;
    color: $text;
    padding: 8px 20px;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 10px;
    display: inline-block;
    border-radius: 2px;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, .6);

    &:hover, &:active, &:focus {
        background: darken($primary, 10%);
        color: $text;
        text-decoration: none;
    }

}

.timeline-card {
    padding: 0!important;

    p {
        padding: 0 20px;
    }

    a {
        margin-left: 20px;
    }
}

blockquote {
    margin-top: 30px;
    color: $secondary-text;
    border-left-color: $primary;
    padding: 0 20px;
}

.date {
    background: $accent;
    display: inline-block;
    color: $text;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
}

@media screen and (max-width: 768px) {

    .timeline {

        &::before {
            left: 50px;
        }

        .timeline-img {
            left: 50px;
        }

        .timeline-content {
            max-width: 100%;
            width: auto;
            margin-left: 70px;
        }

        .timeline-item {

            &:nth-child(even) {

                .timeline-content {
                    float: none;

                }
            }

            &:nth-child(odd) {

                .timeline-content {

                    &::after {
                        content: '';
                        position: absolute;
                        border-style: solid;
                        width: 0;
                        height: 0;
                        top: 30px;
                        left: -15px;
                        border-width: 10px 15px 10px 0;
                        border-color: transparent #f5f5f5 transparent transparent;
                    }
                }

            }
        }
    }

}
</style>
